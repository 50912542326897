<template>
    <TemplateBase
        :entry="entry"
        class="homepage--logged-out"
    >
        <template #hero>
            <HeroHome :entry="entry" />
        </template>

        <template #default>
            <ContentBlocksText
                v-if="entry.introductionText"
                :rich-text="entry.introductionText"
            />
        </template>

        <template #related>
            <ArticleSliderSection
                title="Updates uit de sector"
                class="section--title-small section--spacing-lg"
            />

            <BaseSection
                class="section--spacing-xl section--search-home"
                section-width="content"
                background-style="lightblue"
                :title="entry.searchText || 'Maak gebruik van het kennisnetwerk van TLN en haar leden'"
            >
                <SearchBar />
                <SearchKeywords />
            </BaseSection>
        </template>

        <template #aside>
            <BasePanel title="Direct naar">
                <QuickLinks
                    :links="entry.sidebarLinks"
                />
            </BasePanel>
        </template>
    </TemplateBase>
</template>

<script setup>
import HomeQuery from '~/graphql/queries/Home.graphql';
const { variables }  = useCraftGraphql();

// We don't need the URI for this query
delete variables.uri;

const response = await useAsyncQuery({
    query: HomeQuery,
    variables
});

const { craftEntry: entry } = useCraftPage(response);
</script>


<style lang="less">
.homepage--logged-out {
    .template__body {
        padding-bottom: 0;
    }

    .template__related {
        background: var(--color-lightest);
        padding-top: 0;
        padding-bottom: 0;
    }

    &.template[data-has-sidebar='true'] .section--search-home {
        .section__title {
            .typography-h2();
            color: var(--color-darkerblue);
            text-align: center;
        }

        .section__body,
        .section__header {
            margin-left: auto;
        }

        .section__body {
            margin-top: var(--spacing-md);
        }

        .search-bar {
            margin-bottom: var(--spacing-md);
        }

        @media @q-md-min {
            .section__body,
            .search-keywords {
                margin-top: 2.75rem;
            }
        }
    }

    @media @q-lg-min {
        .template__body {
            padding-bottom: 3rem;
        }
    }
}
</style>
