<template>
    <BaseHero
        :description="!loggedIn ? entry.heroDescription : null"
        :images="!loggedIn ? entry.heroImages : null"
        layout="wide/1-column"
        :background-style="!loggedIn ? 'dark' : 'light'"
        class="hero--home"
    >
        <template #title>
            {{ !loggedIn ? entry.heroTitle || entry.title || 'Transport en Logistiek Nederland' : `Welkom terug, ${user.firstName}` }}
        </template>

        <template #default>
            <LoginCallToActions v-if="!loggedIn" />

            <SearchBar
                v-else
                placeholder-text="Zoek naar thema’s, dossiers of experts"
            />
        </template>
    </BaseHero>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
const authStore = useAuthStore();

defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    }
});

const loggedIn = computed(() => {
    return authStore.loggedIn;
});

const user = computed(() => {
    return authStore.user;
});
</script>
