<template>
    <BaseSection
        v-if="entries && entries.length"
        section-width="bleed"
        :read-more="{
            uri: '/actueel',
            text: 'Meer updates'
        }"
    >
        <template #title>
            {{ title ?? 'Updates' }}
        </template>

        <template #default>
            <BaseSlider
                :controls-enabled="true"
                :items="entries"
            >
                <template v-slot:slide="{ item, isVisible }">
                    <ArticleCard
                        :entry="item"
                        :data-visible="isVisible"
                    />
                </template>
            </BaseSlider>
        </template>
    </BaseSection>
</template>

<script setup>
import ArticlesQuery from '~/graphql/queries/Articles.graphql';

const props = defineProps({
    dossierId: {
        type: String,
        default: null
    },

    title: {
        type: String,
        default: null
    }
});

const variables = {};

if (props.dossierId) {
    variables.dossierId = props.dossierId;
}

const response = await useAsyncQuery({
    query: ArticlesQuery,
    variables
});

const entries = toValue(response.data)?.entries;
</script>
